<template>
	<div class="d-flex flex-column futurepanel fill-height max-height-100">
		<div class="d-flex flex-column py-6 px-3 mb-0" ref="header">
			<div class="futurepanel-title">{{ $t('shipsituation.futurepanel.title') }}</div>
		</div>
		<div class="pb-0 px-3">
			<v-row>
				<v-col>
					<pui-date-field
						:id="`dateini-stopblock`"
						v-model="$store.state.mappanelperiods.fecIniFuturepanel"
						:label="$t('shipsituation.futurepanel.dateini')"
						required
						toplabel
						time
						:max="$store.state.mappanelperiods.fecEndFuturepanel"
						:disabled="processVesselsRunning"
					></pui-date-field>
					<pui-date-field
						:id="`dateend-stopblock`"
						v-model="$store.state.mappanelperiods.fecEndFuturepanel"
						:label="$t('shipsituation.futurepanel.dateend')"
						required
						toplabel
						time
						:min="$store.state.mappanelperiods.fecIniFuturepanel"
						:disabled="processVesselsRunning"
					></pui-date-field>
				</v-col>
				<v-col cols="12" class="pt-0">
					<v-text-field
						ref="searchingtextfuturepanel"
						:placeholder="$t('operationstoolbar.search')"
						v-model="$store.state.mappanelperiods.searchingtextfuturepanel"
						@input="filterVesselsMapByText"
						prepend-inner-icon="far fa-search"
						outlined
						filled
						dense
						background-color="red"
						hide-details
					></v-text-field>
				</v-col>
			</v-row>

			<div
				v-if="!allPanels"
				class="pt-2 mr-2 vessels-subtitle font-weight-medium"
				style="cursor: pointer; color: var(--primary); float: right"
				@click="allPanels = true"
			>
				<v-icon class="mr-2" small color="var(--primary)">far fa-chevron-down</v-icon>
				{{ $t('operations.vessels.all') }}
			</div>
			<div
				v-else
				class="pt-2 mr-2 vessels-subtitle font-weight-medium"
				style="cursor: pointer; color: var(--primary); float: right"
				@click="allPanels = false"
			>
				<v-icon class="mr-2" small color="var(--primary)">far fa-chevron-up</v-icon>
				{{ $t('operations.vessels.none') }}
			</div>
		</div>
		<div class="lists pb-0 px-3">
			<pui-form-loading v-if="processVesselsRunning"></pui-form-loading>
			<cards-panel-items :stops="allStopsComputed" :all="allPanels" :currentmap="currentmap"></cards-panel-items>
		</div>
	</div>
</template>

<script>
import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';
import CardsPanelItems from '../../infoais/vessel/cards/CardsPanelItems.vue';

/** Mixins */
import StopsServiceMixin from '@/mixins/StopsServiceMixin';

export default {
	name: 'instantfuturemappanel',

	mixins: [StopsServiceMixin],

	components: { CardsPanelItems },

	props: {
		currentmap: {
			type: String,
			required: true
		}
	},

	data() {
		return { allPanels: false, searchingtextfuturepanel: '' };
	},

	created() {
		this.isMapPeriodsIsLoaded &&
			ol2map.setVisibleFeatureByVesselText(this.$store.state.mappanelperiods.searchingtextfuturepanel, this.futureStatusCode);
	},

	computed: {
		futureStatusCode() {
			return this.$store.getters.getFutureStatusCode;
		},
		allStops() {
			return this.$store.getters.getStopsFeaturesInstant;
		},
		isMapPeriodsIsLoaded() {
			return this.$store.getters.getMapPeriodsIsLoaded;
		},
		allStopsComputed() {
			const searchTextLower = this.$store.state.mappanelperiods.searchingtextfuturepanel.toLowerCase();
			return this.allStops
				.filter((x) => {
					const isVesselNameMatch = (x.vesselname ?? '').toLowerCase().includes(searchTextLower);
					const isMMSIMatch = (x.mmsi ?? '').toString().toLowerCase().includes(searchTextLower);
					const isTypeDescriptionMatch = (x.vesseltypedescription ?? '').toLowerCase().includes(searchTextLower);
					return (
						isVesselNameMatch ||
						isMMSIMatch ||
						isTypeDescriptionMatch ||
						searchTextLower === ''
					);
				})
				.sort((a, b) => {
					return a.vesselname.localeCompare(b.vesselname);
				});
		},
		processVesselsRunning() {
			return this.$store.getters.getProcessVesselsRunning;
		}
	},

	methods: {
		filterVesselsMapByText() {
			ol2map.setVisibleFeatureByVesselText(this.$store.state.mappanelperiods.searchingtextfuturepanel, this.futureStatusCode);
		}
	},

	watch: {
		'$store.state.mappanelperiods.mapPeriodsIsLoaded'() {
			this.changeInstantLayerOpacityFeatureByStatusid(this.futureStatusCode);
		}
	}
};
</script>

<style lang="postcss" scoped>
.futurepanel {
	background-color: #f7f8fc;
	& .list-icon {
		color: var(--primary);

		&--opened {
			transform: rotateZ(-90deg) !important;
		}
	}

	& .lists {
		overflow-x: hidden;
		overflow-y: auto;

		& .maintitle {
			font-weight: 700;
			font-size: 1.1rem;
			font-weight: bold;
			color: var(--primary);
		}
	}

	& .futurepanel-title {
		font-size: 1.3rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: var(--high);
		margin-bottom: 2px;
	}

	& .futurepanels-subtitle {
		font-size: 14px;
		line-height: 16.5px;
		letter-spacing: normal;
		color: #6f7480;
		margin-top: 2px !important;
	}
}
.btn {
	border-radius: 2px !important;
	min-width: 25px !important;
	min-height: 100%;
	color: gray;
	&.btnleft {
		margin-right: 4px !important;
	}

	& i {
		color: var(--brownish-grey);
	}
	&:hover {
		background-color: var(--primary) !important;
		& i {
			color: var(--negative);
		}
	}
	&.selected {
		background-color: var(--accent !important);
		& i {
			color: var(--negative);
		}
	}
	&.applied {
		background-color: var(--warning);
		& i {
			color: black;
		}
	}
}
</style>
